// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require("@rails/ujs").start()
import Rails from 'rails-ujs';
Rails.start();
require("turbolinks").start()
require("@rails/activestorage").start()
require("channels")
require('jquery');
window.jQuery = $;
window.$ = $;
require('bootstrap')
require('../src/application.scss')
require('data-confirm-modal')
import MicroModal from 'micromodal'
require("jquery-ui/ui/widgets/autocomplete")
require("jquery-ui/themes/base/core.css")
require("jquery-ui/themes/base/menu.css")
require("jquery-ui/themes/base/theme.css")
require("jquery-ui/themes/base/autocomplete.css")


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
//  = require data-confirm-modal




$(document).on('turbolinks:load', function(){

  if($('#modalX-1').length){
    MicroModal.init();
    MicroModal.show('modalX-1');
  }

  $('#username').autocomplete({
      autoFocus: true,
      source: "/pointgets/auto_complete.json",
      minLength: 1
  });
  $('#property_information_username').autocomplete({
      autoFocus: true,
      source: "/pointgets/auto_complete.json",
      minLength: 1
  });
  $('#construction_username').autocomplete({
      autoFocus: true,
      source: "/pointgets/auto_complete.json",
      minLength: 1
  });



  $('.js-auto_address_one').on('click', function(){
    event.preventDefault();
    // console.log('てst_one');
    // console.log($('.js-address_one')[0].value);
    $('.js-auto_address_placeholder_one')[0].value = $('.js-address_one')[0].value
  })

  $('.js-auto_address_two').on('click', function(){
    event.preventDefault();
    // console.log('てst_two');
    // console.log($('.js-address_two')[0].value);
    $('.js-auto_address_placeholder_two')[0].value = $('.js-address_two')[0].value
  })

  // ポイント付与時のフォーム内自動同期
  const jsSelect = document.getElementById('matter');

  jsSelect.addEventListener('change', function() {

    const outText = (this.value);
    if (outText == '0' ) {
      document.getElementById('point').value = "300";
    }
    else if (outText == '1' ) {
      document.getElementById('point').value = "150";
    }
    else if (outText == '2' ) {
      document.getElementById('point').value = "100";
    }
    else if (outText == '3' ) {
      document.getElementById('point').value = "150";
    }
    else if (outText == '4' ) {
      document.getElementById('point').value = "100";
    }
    else if (outText == '5' ) {
      document.getElementById('point').value = "30";
    }
    else if (outText == '6' ) {
      document.getElementById('point').value = "20";
    }
    else if (outText == '7' ) {
      document.getElementById('point').value = "5";
    }
    else if (outText == '8' ) {
      document.getElementById('point').value = "";
    }
  })

});